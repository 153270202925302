import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Repo architecture</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Carbon components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Carbon elements</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Feedback</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "repo-architecture"
    }}>{`Repo architecture`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-themes"
      }}>{`Carbon-themes`}</a>{` has been deprecated in v10, as the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon"
      }}>{`carbon`}</a>{` repository now contains the themes package `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/themes"
      }}>{`@carbon/themes`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-icons"
      }}>{`Carbon-icons`}</a>{` has not yet been deprecated in favor of `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/icons"
      }}>{`@carbon/icons`}</a>{`. That is expected to happen in an upcoming dot release.`}</p>
    <h2 {...{
      "id": "carbon-components"
    }}>{`Carbon components`}</h2>
    <p>{`Carbon components have been completely re-skinned for the new IBM Design Language. The redesign was conducted with maximum backwards-compatibility in mind. Users of v9 should be able to upgrade to v10 automatically with minimal breakage.`}</p>
    <h4 {...{
      "id": "carbon-components-1"
    }}>{`carbon-components`}</h4>
    <p>{`Detailed technical migration docs for Carbon vanilla components are available in the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/components/docs/migration/migrate-to-10.x.md"
      }}>{`carbon-components repo`}</a>{`. Here you will find links to component-level migration information, as well as a list of those components that have been removed or are under development.`}</p>
    <p>{`There is also a dedicated `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/components/src/globals/scss/migrate-to-10.x.md"
      }}>{`Sass migration guide`}</a>{` that covers changes to global styling.`}</p>
    <h4 {...{
      "id": "carbon-components-react"
    }}>{`carbon-components-react`}</h4>
    <p>{`React-specific technical migration docs are available `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/react/docs/migration"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "carbon-elements"
    }}>{`Carbon Elements`}</h2>
    <p>{`All technical migration docs for Carbon Elements are available in the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/docs/migration"
      }}>{`carbon repo’s docs folder`}</a>{`.`}</p>
    <h4 {...{
      "id": "icons"
    }}>{`Icons`}</h4>
    <p>{`Carbon icons underwent a major update for v10. The @carbon/icons package can be now be found in the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/icons"
      }}>{`Carbon Elements repo`}</a>{`. This package will be publically available in an upcoming release, for now please continue to use the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-icons"
      }}><inlineCode parentName="a">{`carbon-icons`}</inlineCode></a>{` repo.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/docs/migration/10.x-icons.md"
      }}>{`icons migration table`}</a>{` to view .`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://carbon-elements.netlify.com/icons/examples/preview/"
      }}>{`Carbon Icons Demo`}</a>{` contains a master table for all Carbon icons, including name, size, preview, download links, quick links for filing issues, module name, and relative path.`}</p>
    <h4 {...{
      "id": "color"
    }}>{`Color`}</h4>
    <p>{`The color token architecture for v10 is based on the v9 architecture, with some minor updates for the new design language. Carbon v9 users will see automatic, non-breaking color updates when they upgrade to v10.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/docs/migration/10.x-color.md"
      }}>{`Carbon v10 Color migration table`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://carbon-elements.netlify.com/colors/examples/preview/"
      }}>{`Primary token-value mapping page`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://carbon-elements.netlify.com/themes/examples/preview/"
      }}>{`Themes token-value mapping table`}</a></p>
    <h4 {...{
      "id": "type"
    }}>{`Type`}</h4>
    <p>{`The type token architecture has been completely redesigned for better clarity and flexibility in v10, moving from a basic type scale model to a more robust token-based architecture.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/docs/migration/10.x-type.md"
      }}>{`Carbon v10 Type migration table`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://carbon-elements.netlify.com/type/examples/preview/"
      }}>{`Font faces/type scale/type styles sample doc`}</a></p>
    <h4 {...{
      "id": "layout-grid-spacing"
    }}>{`Layout, Grid, Spacing`}</h4>
    <p>{`Spacing values will see no change from Carbon v9, though the token names have changed.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/components/src/globals/grid/migrate-to-10.x.md"
      }}>{`Migration table for grid`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/docs/migration/10.x-layout.md"
      }}>{`Migration table for layout`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://carbon-elements.netlify.com/grid/examples/preview/"
      }}>{`Interactive grid examples`}</a></p>
    <h4 {...{
      "id": "motion"
    }}>{`Motion`}</h4>
    <p>{`Motion standards have been revised to meet the new IBM Design Language standards. Motion updates should be coordinated with your product’s design team if possible.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/motion"
      }}>{`New IBM motion package`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/docs/migration/10.x-motion.md"
      }}>{`Motion migration table`}</a></p>
    <h2 {...{
      "id": "feedback"
    }}>{`Feedback`}</h2>
    <p>{`To leave feedback for the Carbon team about this documentation, make suggestions for improvements, or just ask a question, you can `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/issues/new?template=feedback.md&labels=feedback&title=User+feedback+for+Migration+Developers+page"
      }}>{`open an issue`}</a>{` on GitHub.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      